import React from "react";
import styles from "./index.module.scss";
import { Logo } from "../DashboardLayout/sidebar";
import ThemeChanger from "../../components/ThemeChanger";

export default function AuthLayout({ children }) {
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Logo newLogo height={24} />
        <ThemeChanger />
      </header>
      <div className={styles.body}>
        <div>{children}</div>
      </div>
    </div>
  );
}
