import React, { useCallback, useEffect } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { isValidEmail, isValidPassword } from "../../utils/validations";
import { loginWithEmail, rememberMe } from "../../models/auth";
import { connect, useDispatch } from "dva";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const Login = ({ login, loading }) => {
  const dispatch = useDispatch();

  const onFinish = useCallback(
    (values) => login(values.email, values.password),
    [login]
  );

  const onRemember = useCallback(
    (e) => dispatch(rememberMe(e.target.checked)),
    []
  );

  useEffect(() => {
    // dispatch(rememberMe(true));
  }, []);

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Log in</h1>
      <Form
        className={styles.form}
        layout="vertical"
        style={{ textAlign: "left" }}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true },
            {
              validator: (_, val) =>
                isValidEmail(val)
                  ? Promise.resolve()
                  : Promise.reject("Email is invalid"),
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true },
            {
              validator: (_, val) =>
                isValidPassword(val)
                  ? Promise.resolve()
                  : Promise.reject("Must contain atleast 6 characters"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Log in
          </Button>
        </Form.Item>

        <Form.Item
          label={<Link to="/forgot-password">Forgot Password?</Link>}
          className={styles.checkbox}
        >
          <Checkbox defaultChecked={true} onChange={onRemember}>
            <span className={styles.remember_pass}>Remember Me</span>
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default connect(({ auth }) => ({ loading: auth.loading.login }), {
  login: loginWithEmail,
})(Login);
