import React, { useCallback } from "react";
import { Form, Input, Button } from "antd";
import { isValidPassword } from "../../utils/validations";
import { confirmPasswordReset } from "../../models/auth";
import { useDispatch, useSelector } from "dva";
import styles from "../Login/index.module.scss";
import { Link } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import HiddenInput from "../../components/HiddenInput";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.auth.loading.confirmPasswordReset
  );
  const [queryParams] = useQuery();
  const oobCode = queryParams.get("oobCode");

  const onFinish = useCallback((values) => {
    dispatch(
      confirmPasswordReset({ oobCode, newPassword: values.passwordRepeat })
    );
  }, []);

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Reset Password</h1>

      <div className={styles.forgot_caption}>
        Enter the new password and confirm it by re entering to reset your
        password.
      </div>

      <Form
        className={styles.form}
        layout="vertical"
        style={{ textAlign: "left" }}
        onFinish={onFinish}
        requiredMark={false}
      >
        {/* to disable browser autofill */}
        <HiddenInput type="email" />
        <HiddenInput type="password" />

        <Form.Item
          label="Enter new password"
          name="password"
          rules={[
            { required: true },
            {
              validator: (_, val) =>
                isValidPassword(val)
                  ? Promise.resolve()
                  : Promise.reject("Must contain atleast 6 characters"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm new password"
          name="passwordRepeat"
          validateFirst={true}
          dependencies={["password"]}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("This passowrd does not match with the one above!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>

        <div className={styles.return_to_login}>
          Return to{" "}
          <Link to="/login" style={{ fontWeight: "500" }}>
            Log in
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default ResetPassword;
