import React from "react";

function HiddenInput({ type, style }) {
  return (
    <input
      name={type}
      type={type}
      style={{
        width: 0,
        height: 0,
        border: 0,
        padding: 0,
        margin: 0,
        opacity: 0,
        position: "absolute",
        ...(style || {}),
      }}
    />
  );
}

export default HiddenInput;
