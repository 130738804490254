import React, { useCallback } from "react";
import { Form, Input, Button } from "antd";
import { isValidEmail } from "../../utils/validations";
import { requestPasswordReset } from "../../models/auth";
import { useDispatch, useSelector } from "dva";
import styles from "../Login/index.module.scss";
import { Link } from "react-router-dom";

const FrogotPassword = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.auth.loading.requestPasswordReset
  );

  const onFinish = useCallback((values) => {
    dispatch(requestPasswordReset(values.email));
  }, []);

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Forgot Password</h1>

      <div className={styles.forgot_caption}>
        Write your email and we'll send you a link to reset your password
      </div>

      <Form
        className={styles.form}
        layout="vertical"
        style={{ textAlign: "left" }}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true },
            {
              validator: (_, val) =>
                isValidEmail(val)
                  ? Promise.resolve()
                  : Promise.reject("Email is invalid"),
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Send Password Reset Link
          </Button>
        </Form.Item>

        <div className={styles.return_to_login}>
          Return to{" "}
          <Link to="/login" style={{ fontWeight: "500" }}>
            Log in
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default FrogotPassword;
